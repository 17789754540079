












































































import { Vue, Component, Mixins } from 'vue-property-decorator'

// @ts-ignore
import MyDvPage from '$ui/dv/packages/my-dv-page'
// @ts-ignore
import MyDvHeader6 from '$ui/dv/packages/my-dv-header6'
// @ts-ignore
import MyDvTitle from '$ui/dv/packages/my-dv-title'
// @ts-ignore
import MyDvMenu from '$ui/dv/packages/my-dv-menu'
// @ts-ignore
import MyDvBox from '$ui/dv/packages/my-dv-box'
// @ts-ignore
import MyDvIcon from '$ui/dv/packages/my-dv-icon'
// @ts-ignore
import MyDvNumber from '$ui/dv/packages/my-dv-number'
// @ts-ignore
import MyDvText from '$ui/dv/packages/my-dv-text'
// @ts-ignore
import MyDvBorder6 from '$ui/dv/packages/my-dv-border6'

// @ts-ignore
import MyDvAdorn2 from '$ui/dv/packages/my-dv-adorn2'

// @ts-ignore
import MyDvGeo from '$ui/dv/packages/my-dv-geo'

// @ts-ignore
import MyBreadcrumb from '$ui/components/my-breadcrumb'

// @ts-ignore
import geoArray from '$ui/charts/geo/index.json'

@Component({
  components: {
    MyDvPage,
    MyDvHeader6,
    MyDvTitle,
    MyDvMenu,
    MyDvBox,
    MyDvIcon,
    MyDvNumber,
    MyDvText,
    MyDvBorder6,
    MyDvAdorn2,
    MyDvGeo,
    MyBreadcrumb,
  },
})
export default class extends Mixins() {
  menus = [{ text: '场馆' }, { text: '用户' }, { text: '运营' }]
  defaultMenuActive = 0

  stat = [
    { label: '申请装机数量', value: 1469 },
    { label: '装机中数量', value: 16 },
    { label: '已装机数量', value: 2 },
    { label: '运营中数量', value: 2 },
  ]

  breadcrumb = [{ label: '全国', geo: 'china.json' }]

  mapName = 'china.json'

  type = {
    人口数量: 'effectScatter',
  }

  json({ map }: any) {
    // @ts-ignore
    this.$refs.geo.loading = true
    const [path, file] = map.split('/')

    if (!file) {
      return import('$ui/charts/geo/china' + '.json')
    }

    return path === 'province'
      ? import('$ui/charts/geo/province/' + file)
      : import('$ui/charts/geo/city/' + file)
  }

  get activeIndex() {
    return this.breadcrumb.length - 1
  }

  handleClick(params: any) {
    const name = params.name.replace('市', '')
    const geoItem = geoArray.find((item: any) => item.name === name)
    if (geoItem) {
      this.mapName = geoItem.geo
      this.breadcrumb.push({
        label: params.name,
        ...geoItem,
      })
    }
  }

  handleMapRegister() {
    // @ts-ignore
    this.$refs.geo.loading = false
  }

  handleMenuClick(item: any, index: number) {
    this.breadcrumb.splice(index + 1)
    this.mapName = item.geo
  }

  loader({ name }: any) {
    return Promise.resolve({
      columns: ['省份', '人口数量'],
      rows: [
        ['广州', 225344],
        ['广州市', 1323],
      ],
    })
  }
}
